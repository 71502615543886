import { isBrowser } from '@patrianna/shared-utils/helpers'
export const SOCIAL_REGISTRATION = 'SOCIAL_REGISTRATION'
export const GOOGLE_REGISTRATION_VALUES = 'GOOGLE_REG_VALUES'
export const FB_REGISTRATION_VALUES = 'FB_REG_VALUES'
export const MANUAL_REGISTRATION_VALUES = 'MANUAL_REG_VALUES'
export const LAST_ACTIVE_REG_STEP = 'LAST_ACTIVE_REG_STEP'
export const REWARD_CODE = 'REWARD_CODE'
export const PREFERRED_GAME = 'PREFERRED_GAME'
export const ZENDESK_JWT_TOKEN = 'zendeskJwtToken'
// temp solution, remove once BE sent correct offer data in response (GC, SC)
export const OFFER_TEMP = 'OFFER_TEMP'
export const S_SESSION_ID = 'S_SESSION_ID'
export const S_P_SESSION_ID = 'S_P_SESSION_ID'

export type SessionStorageKey =
  | typeof SOCIAL_REGISTRATION
  | typeof GOOGLE_REGISTRATION_VALUES
  | typeof FB_REGISTRATION_VALUES
  | typeof MANUAL_REGISTRATION_VALUES
  | typeof LAST_ACTIVE_REG_STEP
  | typeof REWARD_CODE
  | typeof PREFERRED_GAME
  | typeof ZENDESK_JWT_TOKEN
  // temp solution, remove once BE sent correct offer data in response (GC, SC)
  | typeof OFFER_TEMP
  | typeof S_SESSION_ID
  | typeof S_P_SESSION_ID

export const setDataToSessionStorage = (key: SessionStorageKey, value: object | string | boolean): void => {
  try {
    if (isBrowser()) {
      window.sessionStorage.setItem(key, JSON.stringify(value))
    }
  } catch (err) {
    console.log(err)
  }
}

export const getDataFromSessionStorage = <T>(key: SessionStorageKey): T | null => {
  try {
    if (!isBrowser()) {
      return null
    }

    const data = window.sessionStorage.getItem(key)
    if (!data || data === 'undefined') {
      return null
    }
    const parsedData = JSON.parse(data) as T

    return parsedData as T
  } catch (err) {
    console.log(err)

    return null
  }
}

export const removeDataFromSessionStorage = (key: SessionStorageKey): void => {
  try {
    if (isBrowser()) {
      window.sessionStorage.removeItem(key)
    }
  } catch (err) {
    console.log(err)
  }
}
